import * as Sentry from "@sentry/react";
import { isSameWeek, startOfWeek } from "date-fns";
import React, { useEffect, useState } from "react";
import PageContentType from "../PageContentType";
import Permission from "../Permission";
import Weather from "../Weather";
import { CalendarEvent } from "../components/Calendar/projectCalendarTypes";
import mapIcons from "../mapIcons";
import PlotAPI from "../plotAPI";
import TeamInfo from "../teamInfo";
import GlobalStoreContext from "./GlobalStoreContext";
import useActiveProjects from "./useActiveProjects";
import useAnyCable from "./useAnyCable";
import useCurrentProjectInfo from "./useCurrentProjectInfo";
import useCurrentTeam from "./useCurrentTeam";
import useProjectInfo from "./useProjectInfo";
import useUIHook from "./useUIHook";
import useUserInfo from "./useUserInfo";
import useWindowSize from "./useWindowSize";

function GlobalStoreProvider(props: { children: React.ReactNode }) {
    const [pageContentType, setPageContentType] = useState<PageContentType | null>(null); // Type of content being shown in the content area

    const [currentViewedTeamInfo, _setCurrentViewedTeamInfo] = useState<TeamInfo | null>(null);

    const [newBroadcastMessage, setNewBroadcastMessage] = useState<boolean>(false);

    // TODO: These will be wrapped up into a hook for all projectInfo data,
    //       not writing it now as that hook will support multiple projects
    //       instead of just one
    const [currentProjectWeather, setCurrentProjectWeather] = useState<null | any>(null);
    const [currentAppVersion, _setCurrentAppVersion] = useState<null | string>(null);
    const [thisWeeksEvents, setThisWeeksEvents] = React.useState<{ day_index: number; event: CalendarEvent }[]>([]);

    const {
        activeSidebarKey,
        setActiveSidebarKey,
        collapseSidebar,
        setCollapseSidebar,
        showNotificationsDrawer,
        setShowNotificationsDrawer,
        addTeamToProjectModal,
        setAddTeamToProjectModal,
    } = useUIHook();

    const { windowSize, isMobile } = useWindowSize();

    const { currentProjectToken, setCurrentProjectToken, currentProjectInfo, reloadCurrentProject } =
        useCurrentProjectInfo();

    const {
        userInfo,
        setUserInfo,
        gottenUserInfo,
        userPic,
        setUserPic,
        reloadUserInfo,
        loggedIn,
        setUserTheme,
        usersTeams,
        reloadUsersTeams,
    } = useUserInfo({
        pageContentType,
        currentProjectInfo,
    });

    const { currentTeam, currentTeamsProjects, setCurrentTeam, reloadCurrentTeam, reloadCurrentTeamsProjects } =
        useCurrentTeam({
            usersTeams,
        });

    const loadThisWeekEvents = (project: any) => {
        const today = new Date();
        const firstDayOfWeek = startOfWeek(today);
        const month = firstDayOfWeek.getMonth();
        const year = firstDayOfWeek.getFullYear();
        const projectToken = project?.unique_token || currentProjectToken;
        const projectSubdomain = project?.claimed_by_team_subdomain || "projects";

        PlotAPI.getCalendarsEventsPageForProject(projectSubdomain, projectToken, year, month)
            .then((response) => {
                return response.map((event) => CalendarEvent.fromAPIObject(event));
            })
            .then((calendarEvents) => {
                const weekEvents = new Array<{ day_index: number; event: CalendarEvent }>();

                calendarEvents.forEach((event) => {
                    if (isSameWeek(today, event.start)) {
                        weekEvents.push({ day_index: event.start.getDay(), event: event });
                    }
                });

                setThisWeeksEvents(weekEvents);
            });
    };

    const { cableApp, dashboardNotificationsChannel, mapChannel, deliveriesChannel } = useAnyCable({
        projectInfo: currentProjectInfo,
        currentTeamsProjects: currentTeamsProjects,
        setNewBroadcastMessage: setNewBroadcastMessage,
        reloadDeliveries: loadThisWeekEvents,
    });

    const { currentActiveProjects } = useActiveProjects({
        projects: currentTeamsProjects.member_of,
        currentProjectInfo: currentProjectInfo,
        pageContentType: pageContentType,
    });

    const {
        projectInfo,
        mapAreasByProject,
        mapAreasForProject,
        refreshMapAreasByProject,
        equipmentByProject,
        equipmentForProject,
        refreshEquipmentByProject,
        teamsByProject,
        teamsForProject,
        refreshTeamsByProject,
        vendorsByProject,
        vendorsForProject,
        refreshVendorsByProject,
    } = useProjectInfo({
        projects: currentActiveProjects,
    });

    useEffect(() => {
        if (currentProjectInfo) {
            loadThisWeekEvents(currentProjectInfo);

            Weather.getWeatherInformation(`${currentProjectInfo.lat}, ${currentProjectInfo.lon}`, "forecast").then(
                (response) => {
                    setCurrentProjectWeather(response);
                }
            );
        }
    }, [currentTeamsProjects, currentProjectInfo]);

    useEffect(() => {
        setCurrentAppVersion();
    }, []);

    useEffect(() => {
        if (
            pageContentType === PageContentType.TEAM ||
            pageContentType == PageContentType.DELIVERY ||
            pageContentType == PageContentType.PROJECT
        ) {
            PlotAPI.getCurrentSubdomainTeam().then((response) => {
                _setCurrentViewedTeamInfo(response ? new TeamInfo(response) : null);
            });
        }
    }, [pageContentType]);

    const setCurrentAppVersion = () => {
        fetch("/version.txt")
            .then((response) => response.text())
            .then((text) => _setCurrentAppVersion(text));
    };

    const reloadCurrentViewedTeamInfo = (): void => {
        PlotAPI.getCurrentSubdomainTeam().then((response) => {
            _setCurrentViewedTeamInfo(response ? new TeamInfo(response) : null);
        });
    };

    /**
     * Get current weather for a project  jobsite
     * @returns weather if found, else null.
     */
    const getCurrentProjectWeather = (): any | null => {
        Weather.getWeatherInformation(`${currentProjectInfo.lat}, ${currentProjectInfo.lon}`, "forecast").then(
            (response) => {
                if (response.ok) {
                    setCurrentProjectWeather(response);
                } else {
                    Sentry.captureMessage("Error getting weather information" + response);
                }
            }
        );
    };

    const userHasPermission = (permission: Permission, team: number, project: number | null) => {
        let loaded_projects_if_needed = false;
        if (project == null) {
            // Do need to ensure loaded
            loaded_projects_if_needed = true;
        } else if (currentProjectToken && project == currentProjectInfo?.id && currentProjectInfo) {
            // We want the viewed project and it is loaded
            loaded_projects_if_needed = true;
        } else if (currentTeamsProjects) {
            // We want any other project which should only be current users teams projects
            loaded_projects_if_needed = true;
        }

        let loaded_teams_if_needed = false;
        if (team == null) {
            // Checking explict null in case of team id 0
            loaded_teams_if_needed = true;
        } else if ((currentTeam && currentTeam.id == team) || currentTeam == null) {
            loaded_teams_if_needed = true;
        } else if (currentViewedTeamInfo && currentViewedTeamInfo.id == team) {
            loaded_teams_if_needed = true;
        }

        if (userInfo && loaded_projects_if_needed && loaded_teams_if_needed) {
            return userInfo.permission(permission, team, project);
        }
        return false;
    };

    return (
        <GlobalStoreContext.Provider
            value={{
                userInfo,
                setUserInfo,
                gottenUserInfo,
                userPic,
                setUserPic,
                reloadUserInfo,
                loggedIn,
                usersTeams,
                reloadUsersTeams,
                currentTeam,
                setCurrentTeam,
                reloadCurrentTeam,
                reloadCurrentTeamsProjects,
                currentTeamsProjects,
                pageContentType,
                setPageContentType,
                currentProjectToken,
                setCurrentProjectToken,
                currentProjectInfo,
                currentViewedTeamInfo,
                reloadCurrentProject,
                reloadCurrentViewedTeamInfo,
                windowSize,
                isMobile,
                cableApp: cableApp,
                mapIcons,
                currentProjectWeather,
                getCurrentProjectWeather,
                userHasPermission,
                currentAppVersion,
                setCurrentAppVersion,
                dashboardNotificationsChannel: dashboardNotificationsChannel,
                newBroadcastMessage,
                setNewBroadcastMessage,
                mapChannel: mapChannel,
                deliveriesChannel: deliveriesChannel,
                setUserTheme,
                thisWeeksEvents,
                activeSidebarKey,
                setActiveSidebarKey,
                collapseSidebar,
                setCollapseSidebar,
                showNotificationsDrawer,
                setShowNotificationsDrawer,
                addTeamToProjectModal,
                setAddTeamToProjectModal,
                projectInfo,
                mapAreasByProject,
                mapAreasForProject,
                refreshMapAreasByProject,
                equipmentByProject,
                equipmentForProject,
                refreshEquipmentByProject,
                teamsByProject,
                teamsForProject,
                refreshTeamsByProject,
                vendorsByProject,
                vendorsForProject,
                refreshVendorsByProject,
            }}
        >
            {props.children}
        </GlobalStoreContext.Provider>
    );
}

export default GlobalStoreProvider;
