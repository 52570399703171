import { useEffect, useState } from "react";
import { Equipment } from "../components/Equipment/equipmentTypes";
import PlotAPI from "../plotAPI";
import TeamInfo from "../teamInfo";

interface useProjectInfoProps {
    projects: any[];
}

const useProjectInfo = (props: useProjectInfoProps) => {
    const [mapAreasByProject, setProjectsMapAreas] = useState<{ unique_token: string; areas: any[] }[]>([]);
    const [equipmentByProject, setProjectsEquipment] = useState<{ unique_token: string; equipment: Equipment[] }[]>([]);
    const [teamsByProject, setProjectsTeams] = useState<{ unique_token: string; teams: TeamInfo[] }[]>([]);
    const [vendorsByProject, setvendorsByProject] = useState<{ unique_token: string; vendorTeams: TeamInfo[] }[]>([]);

    useEffect(() => {
        refreshMapAreasByProject();
        refreshEquipmentByProject();
        refreshTeamsByProject();
        refreshVendorsByProject();
    }, [props.projects]);

    const refreshMapAreasByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getProjectMapLayers(project.unique_token, project.claimed_by_team_subdomain || "projects").then(
                    (layers) => {
                        return {
                            unique_token: project.unique_token,
                            areas: layers.map((layer) => layer.features).flat(),
                        };
                    }
                )
            )
        ).then((layers) => {
            setProjectsMapAreas(layers);
        });
    };

    const refreshEquipmentByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getEquipmentsForProject(
                    project.unique_token,
                    project.claimed_by_team_subdomain || "projects"
                ).then((equipment) => {
                    return {
                        unique_token: project.unique_token,
                        equipment: equipment,
                    };
                })
            )
        ).then((equipment) => {
            setProjectsEquipment(equipment);
        });
    };

    const refreshTeamsByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getPublicProjectMembers(
                    project.unique_token,
                    project.claimed_by_team_subdomain || "public"
                ).then((response) => {
                    return {
                        unique_token: project.unique_token,
                        teams: response,
                    };
                })
            )
        ).then((teams) => {
            setProjectsTeams(teams);
        });
    };

    const refreshVendorsByProject = () => {
        Promise.all(
            props.projects.map((project) =>
                PlotAPI.getvendorsForProject(project.unique_token, project.claimed_by_team_subdomain || "public").then(
                    (response) => {
                        return {
                            unique_token: project.unique_token,
                            vendorTeams: response.map((team) => new TeamInfo(team)),
                        };
                    }
                )
            )
        ).then((vendors) => {
            setvendorsByProject(vendors);
        });
    };

    const projectInfo = (projectId: string | number): any | null => {
        return (
            props.projects.find(
                (projectInfo) => projectInfo.id === projectId || projectInfo.unique_token == projectId
            ) || null
        );
    };

    const mapAreasForProject = (projectId: string | number): any[] => {
        const projectToken = projectInfo(projectId)?.unique_token;

        return mapAreasByProject.find((project) => project.unique_token === projectToken)?.areas || [];
    };

    const equipmentForProject = (projectId: string | number): Equipment[] => {
        const projectToken = projectInfo(projectId)?.unique_token;

        return (
            equipmentByProject.find((equipmentForProject) => equipmentForProject.unique_token === projectToken)
                ?.equipment || []
        );
    };

    const teamsForProject = (projectId: string | number): TeamInfo[] => {
        const projectToken = projectInfo(projectId)?.unique_token;

        return teamsByProject.find((projectTeam) => projectTeam.unique_token === projectToken)?.teams || [];
    };

    const vendorsForProject = (projectId: string | number): TeamInfo[] => {
        const projectToken = projectInfo(projectId)?.unique_token;

        return vendorsByProject.find((projectVendor) => projectVendor.unique_token === projectToken)?.vendorTeams || [];
    };

    return {
        projectInfo,
        mapAreasByProject,
        mapAreasForProject,
        refreshMapAreasByProject,
        equipmentByProject,
        equipmentForProject,
        refreshEquipmentByProject,
        teamsByProject,
        teamsForProject,
        refreshTeamsByProject,
        vendorsByProject,
        vendorsForProject,
        refreshVendorsByProject,
    };
};

export default useProjectInfo;
